import { LOGGED_IN_COMPONENT_SELECTOR } from "./pianoShowIfLoggedIn";
import { LOGGED_OUT_COMPONENT_SELECTOR } from "./pianoShowIfLoggedOut";

export const showLoggedInComponents = () => {
  document
    .querySelectorAll(LOGGED_IN_COMPONENT_SELECTOR)
    .forEach((el) => el.classList.remove("hidden"));
};

export const hideLoggedInComponents = () => {
  document
    .querySelectorAll(LOGGED_IN_COMPONENT_SELECTOR)
    .forEach((el) => el.classList.add("hidden"));
};

export const showLoggedOutComponents = () => {
  document
    .querySelectorAll(LOGGED_OUT_COMPONENT_SELECTOR)
    .forEach((el) => el.classList.remove("hidden"));
};

export const hideLoggedOutComponents = () => {
  document
    .querySelectorAll(LOGGED_OUT_COMPONENT_SELECTOR)
    .forEach((el) => el.classList.add("hidden"));
};

export const daysBetween = (start: number, end: number) => {
  return Math.floor(Math.abs(start - end) / 86400);
};

export const daysLeft = (end: number) => {
  return daysBetween(Date.now() / 1000, end);
};
